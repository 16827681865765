/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable array-callback-return */
/* eslint-disable react/react-in-jsx-scope */
import L from 'leaflet'
import { useContext, useEffect } from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import 'react-leaflet-markercluster'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { AuthContext } from '@/contexts/auth'
import 'leaflet.heat'

type IListenersLocationResponse = {
    latitude: number
    longitude: number
    listeners: number | string
}[]

interface MapProps {
    coordinates: IListenersLocationResponse | undefined,
    address: {
        city: string,
        state: string
        country: string
    }
}

function MapUpdater({ coordinates, address }: MapProps) {
    const map = useMap()
    const _window: any = window

    async function getCoordinates(city?: string, state?: string, country?: string) {
        if (city) {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?city=${city}&state=${state}&country=${country}&format=json`
                )
                const data = await response.json()
                if (data.length > 0) {
                    const { lat, lon } = data[0]
                    return { lat: () => lat, lng: () => lon, zoom: 10 }
                }
            } catch (e) { }
        }

        return {
            lat: () => -20.58087,
            lng: () => -57.977812,
            zoom: 4
        }
    }

    const adjustHeatmapOpacity = (shouldAdjustOpacity = false) => {
        const styleId = 'heatmap-opacity-style'
        let styleElement = document.getElementById(styleId)

        if (!styleElement) {
            styleElement = document.createElement('style')
            styleElement.id = styleId
            document.head.appendChild(styleElement)
        }

        const opacity = shouldAdjustOpacity ? 0.5 : 0.7

        styleElement.textContent = `
          .leaflet-heatmap-layer {
            opacity: ${opacity};
          }
        `
    }

    async function initialize(map: any) {
        const { lat, lng, zoom } = await getCoordinates(address?.city, address?.state, address?.country)

        map.setView([lat(), lng()], zoom)

        if (map._layers) {
            Object.keys(map._layers).forEach(layerId => {
                const layer = map._layers[layerId]
                if (layer instanceof L.MarkerClusterGroup) {
                    map.removeLayer(layer)
                }
            })
        }

        const cluster = new L.MarkerClusterGroup({
            disableClusteringAtZoom: 20,
            animateAddingMarkers: true,
            spiderfyOnMaxZoom: false,
            singleMarkerMode: true,
            polygonOptions: {
                opacity: 0,
                fillOpacity: 0
            }
        })

        const data = coordinates?.map(location => [location.latitude, location.longitude, location.listeners]) || []
        const shouldAdjustOpacity = coordinates?.some(location => Number(location.listeners) > 50)

        L.heatLayer(data, {
            radius: 20,
            blur: 15,
            maxZoom: 17,
            max: 1.0,
            gradient: {
                0: 'rgba(0, 0, 255, 0)', // Transparent blue
                0.5: 'rgba(0, 255, 0, 1)', // Green
                0.7: 'rgba(255, 255, 0, 1)', // Yellow
                0.9: 'rgba(255, 0, 0, 1)' // Red
            },
            minOpacity: 0.5
        }).addTo(cluster)

        map.addLayer(cluster)

        adjustHeatmapOpacity(shouldAdjustOpacity)
    }

    useEffect(() => {
        if (map) {
            initialize(map)
        }
    }, [map, coordinates])

    return null
}

export default function HeatMap({ coordinates, address }: MapProps) {
    const { darkMode } = useContext(AuthContext)

    return (
        <MapContainer
            id='map'
            style={{ width: '100%', height: '100%', backgroundColor: darkMode ? '#181B1F' : '#F2F2F2' }}
            // @ts-ignore
            center={[-20.58087, -57.977812]}
            zoom={4}
            scrollWheelZoom={false}
            zoomControl={false}
            dragging={false}
            doubleClickZoom={false}
            touchZoom={false}
            boxZoom={false}
            keyboard={false}
            attributionControl={false}
            minZoom={2}
        >
            {darkMode
                ? <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    // @ts-ignore
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
                : <TileLayer
                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                />
            }

            <MapUpdater coordinates={coordinates} address={address} />
        </MapContainer>
    )
}
